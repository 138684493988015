import React from "react"
import { useState } from "react"
import Helmet from "react-helmet"
import ContactUsBanner from "../images/contact-us-image.svg"
import RegisterVehicle from "../images/register-vehicle.svg"
import SuccessOrderImage from "../images/Asset18.png"
// import { ReactComponent as LocationIcon } from "../images/location-sign.svg"
import LocationIcon from "../images/location-sign.svg"
import AutoCompletePlaces from "../components/autoCompletePlaces"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { createOrder, getEstimate } from "../services/order"
import { commalizeNumber } from "../utils/helper"
import {
  geocodeByAddress,
  geocodeByLatLng,
  getLatLng,
} from "react-google-places-autocomplete"

import "../styles/get-estimate.css"

const GetEstimate = () => {
  const [pickUpAddress, setPickUpAddress] = useState(null)
  const [destinationAddress, setDestinationAddress] = useState(null)
  const [loadingPrice, setLoadingPrice] = useState(false)
  const [stage, setStage] = useState(1)
  const [estimate, setEstimate] = useState({})

  const [sameInformation, setSameInformation] = useState(false)

  const [errors, setErrors] = useState([])

  const [formData, setFormData] = useState({
    pickUpFullName: "",
    pickUpPhone: "",
    destinationFullName: "",
    destinationPhone: "",
    orderType: "small",
  })

  const handleGetEstimate = () => {
    let errorsToDisplay = []
    if (formData.orderType.length === 0) {
      errorsToDisplay.push("What is the size of the package?")
    }
    if (!pickUpAddress) {
      errorsToDisplay.push("Please add a pick-up address")
    }
    if (!destinationAddress) {
      errorsToDisplay.push("Please add a Destination address")
    }

    if (errorsToDisplay.length > 0) {
      setErrors(errorsToDisplay)
      return
    } else {
      setErrors([errorsToDisplay])
    }
    const payload = {
      orderType: formData.orderType,
      pickup: { ...pickUpAddress.coordinates, address: pickUpAddress.label },
      destination: {
        ...destinationAddress.coordinates,
        address: destinationAddress.label,
      },
    }
    setLoadingPrice(true)
    getEstimate(payload)
      .then(res => {
        setErrors([])
        setEstimate(res)
        setStage(stage + 1)
      })
      .catch(err => {
        if (err.message) {
          const error = err.response.data.errors
          const errorsArray = Object.values(error).reduce(
            (result, messages) => {
              return result.concat(messages)
            }
          )
          setErrors(errorsArray)
        }
      })
      .finally(() => {
        setLoadingPrice(false)
      })
  }
  const handleOrderDelivery = () => {
    setLoadingPrice(true)

    const payload = {
      pickupContact: {
        fullName: formData.pickUpFullName,
        phone: formData.pickUpPhone,
      },
      deliveryContact: {
        fullName: sameInformation
          ? formData.pickUpFullName
          : formData.destinationFullName,
        phone: sameInformation
          ? formData.pickUpPhone
          : formData.destinationPhone,
      },
      orderType: formData.orderType,
      pickup: { ...pickUpAddress.coordinates, address: pickUpAddress.label },
      destination: {
        ...destinationAddress.coordinates,
        address: destinationAddress.label,
      },
    }

    createOrder(payload)
      .then(res => {
        setErrors([])
        setEstimate(res)
        setStage(stage + 1)
      })
      .catch(err => {
        if (err.message) {
          const error = err.response.data.errors
          const errorsArray = Object.values(error).reduce(
            (result, messages) => {
              return result.concat(messages)
            }
          )
          setErrors(errorsArray)
        }
      })
      .finally(() => {
        setLoadingPrice(false)
      })
  }

  const proceed = () => {
    if (stage === 1) {
      handleGetEstimate()
    } else {
      handleOrderDelivery()
    }
  }

  const handleInputChange = e => {
    setFormData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const getCurrentLocation = type => {
    const successCallback = position => {
      if (type === "PICKUP") {
        geocodeByLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
          .then(results => {
            setErrors([])
            const location = results.filter(result => {
              result.types.includes("street_address")
            })

            setPickUpAddress({
              label: location.length
                ? location[0].formatted_address
                : results[0].formatted_address,
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
          })
          .catch(error => {
            setErrors([error])
          })
      } else {
        geocodeByLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
          .then(results => {
            setErrors([])
            const location = results.filter(result => {
              result.types.includes("street_address")
            })
            setDestinationAddress({
              label: location.length
                ? location[0].formatted_address
                : results[0].formatted_address,
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
          })
          .catch(error => {
            setErrors([error])
          })
      }
    }

    const errorCallback = error => {
      setErrors(["Failed to get current location", error])
    }

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "full-page contact-us-page",
        }}
      />
      <SEO title="Book a delivery" />
      <section className="section-intro">
        <div className="section--content align-items-center">
          <div className="home--left--section">
            <h1 className="home--banner-text cta-text">
              Book a Delivery, we are here for you
            </h1>
            <h2 className="home--banner-text-sub">We are available 24/7</h2>
            <h4 className="home--banner-text-small">
              Book a delivery, the rider is alerted, your package is delivered!
            </h4>

            {errors &&
              errors.map(error => {
                return <p className="error">{error}</p>
              })}

            <div className="delivery-details">
              {stage === 1 && (
                <StageOne
                  data={formData}
                  onChange={handleInputChange}
                  pickUpAddress={pickUpAddress}
                  setPickUpAddress={setPickUpAddress}
                  destinationAddress={destinationAddress}
                  getCurrentLocation={getCurrentLocation}
                  setDestinationAddress={setDestinationAddress}
                />
              )}
              {stage === 2 && (
                <form>
                  <StageTwo
                    data={formData}
                    estimate={estimate}
                    onChange={handleInputChange}
                    pickUpAddress={pickUpAddress}
                    destinationAddress={destinationAddress}
                    sameInformation={sameInformation}
                    setSameInformation={setSameInformation}
                  />
                </form>
              )}
              {stage === 3 && (
                <div>
                  <p className="">Your Order has been placed</p>
                  <p className="">We would reach out to you shortly!</p>
                </div>
              )}

              {!(stage === 3) && (
                <div className="buttons">
                  {stage > 1 && (
                    <button
                      onClick={() => setStage(stage - 1)}
                      className="cta submit-form delivery--back-button"
                    >
                      back
                    </button>
                  )}
                  <button
                    onClick={() => proceed()}
                    className="cta submit-form delivery--cta"
                    disabled={
                      stage === 2
                        ? !sameInformation
                          ? Object.values(formData).some(
                              value => value.length === 0
                            )
                          : Object.values({
                              pickUpFullName: formData.pickUpFullName,
                              pickUpPhone: formData.pickUpPhone,
                            }).some(value => value.length === 0)
                        : false
                    }
                  >
                    {stage === 2 ? "Book Delivery" : "See Price"}
                    {loadingPrice && (
                      <p className="fa-spin fa-pulse loading"></p>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="home--right--section">
            <div className="home-delivery--illustration">
              {stage === 1 ? (
                <img src={ContactUsBanner} alt="" />
              ) : stage === 2 ? (
                <img src={RegisterVehicle} alt="" />
              ) : (
                <img src={SuccessOrderImage} alt="" />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default GetEstimate

const StageOne = ({
  data,
  onChange,
  pickUpAddress,
  setPickUpAddress,
  destinationAddress,
  getCurrentLocation,
  setDestinationAddress,
}) => {
  return (
    <div className="delivery-details stage-one">
      <select
        name="orderType"
        id="orderType"
        value={data.orderType}
        onChange={e => onChange(e)}
      >
        <option value="small">Small package</option>
        <option value="medium">Medium package</option>
        <option value="large">Large package</option>
      </select>
      <div className="autocomplete-input">
        <AutoCompletePlaces
          style={{ flex: 1 }}
          address={pickUpAddress}
          setAddress={setPickUpAddress}
          placeholder="Enter Pickup Location"
        />
        {/* <span className="location-icon"></span> */}
        <img
          className="location-icon"
          src={LocationIcon}
          onClick={() => getCurrentLocation("PICKUP")}
        />
        {/* <LocationIcon className="location-icon" /> */}
      </div>
      <div className="autocomplete-input">
        <AutoCompletePlaces
          style={{ flex: 1 }}
          address={destinationAddress}
          setAddress={setDestinationAddress}
          placeholder="Enter Destination Location"
        />
        <img
          className="location-icon"
          src={LocationIcon}
          onClick={() => getCurrentLocation("DESTINATION")}
        />
        {/* <LocationIcon className="location-icon" /> */}
        {/* <span className="location-icon"></span> */}
      </div>
    </div>
  )
}

const StageTwo = ({
  data,
  onChange,
  estimate,
  pickUpAddress,
  destinationAddress,
  sameInformation,
  setSameInformation,
}) => {
  return (
    <div className="delivery-details stage-two">
      <div className="fare-details">
        <p className="">Your fare is </p>
        <p className="price">{commalizeNumber(estimate?.fare)}</p>
      </div>

      <div className="ride-details">
        <p className="">
          From <span>{pickUpAddress?.label}</span>
        </p>
        <p className="">
          To <span>{destinationAddress?.label}</span>
        </p>
        <p>
          Total Distance: <span>{Math.round(estimate?.distance)}KM</span>
        </p>
      </div>

      <div className="delivery-info">
        <p>PickUp Details</p>

        <input
          typeof="text"
          placeholder="Full Name"
          name="pickUpFullName"
          value={data.pickUpFullName}
          onChange={e => onChange(e)}
        />
        <input
          // typeof="text"
          type="tel"
          placeholder="Phone Number"
          name="pickUpPhone"
          value={data.pickUpPhone}
          onChange={e => onChange(e)}
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          required
        />
      </div>

      {/* {!sameInformation && ( */}
      <div
        className={`delivery-info ${sameInformation ? "disable-input" : ""}`}
      >
        <p>Destination Details</p>

        <input
          disabled={sameInformation}
          typeof="text"
          placeholder="Full Name"
          name="destinationFullName"
          value={
            !sameInformation ? data.destinationFullName : data.pickUpFullName
          }
          onChange={e => onChange(e)}
        />

        <input
          disabled={sameInformation}
          type="tel"
          placeholder="Phone Number"
          name="destinationPhone"
          value={!sameInformation ? data.destinationPhone : data.pickUpPhone}
          onChange={e => onChange(e)}
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          required
        />
      </div>
      {/* )} */}
      <div className="same-delivery-address">
        <label for="checkboxSameDelivery">Use Same Address as Pick Up</label>
        <input
          type="checkbox"
          id="checkboxSameDelivery"
          name=""
          value={sameInformation}
          onChange={() => {
            setSameInformation(!sameInformation)
          }}
        />
      </div>
    </div>
  )
}
