import { api } from "../api"

export const getEstimate = async payload => {
  try {
    const response = await api.post("/Orders/get-estimate", payload)
    return response.data.data
  } catch (err) {
    throw err
  }
}
export const createOrder = async payload => {
  try {
    const response = await api.post("/Orders", payload)
    return response.data.data
  } catch (err) {
    throw err
  }
}
