import React from "react"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete"

import "../styles/get-estimate.css"

const AutoCompletePlaces = ({
  address,
  setAddress,
  placeholder,
  getCoordinates = true,
}) => {
  const handleChange = e => {
    if (getCoordinates) {
      geocodeByAddress(e.value.description)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          const addressObject = {
            ...e,
            coordinates: { lat, lng },
          }
          setAddress(addressObject)
        })
    } else {
      setAddress(e)
    }
  }
  return (
    <>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyD1B8Z8VuxdSjkE9TVWPCwcCCt4zGSpXc0"
        selectProps={{
          value: address,
          onChange: handleChange,
          placeholder: placeholder ?? "Search a place",
          styles: {
            input: provided => ({
              ...provided,
            }),
            option: provided => ({
              ...provided,
            }),
            container: provided => ({
              ...provided,
              flex: 1,
            }),
            control: provided => ({
              ...provided,
              border: "none",
              borderBottom: "1px solid hsl(0, 0%, 80%)",
            }),
          },
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["ng"],
          },
        }}
      />
    </>
  )
}

export default AutoCompletePlaces
